import React from 'react';
import ReactDOM from 'react-dom/client';
// import { Provider } from 'react-redux';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
// import store from './commons/redux/store';
import './commons/utils/i18nextConf';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// import {
//   BrowserRouter,
// } from "react-router-dom";
import { LandingPage } from './pages';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <LandingPage />
  // <Provider store={store}>
  //   <BrowserRouter>
  //     <App />
  //   </BrowserRouter>
  // </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

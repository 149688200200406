import React, {useState} from 'react';
import {
    Box,
    Menu,
    Container,
    Typography,
    Link,
    MenuItem,
    Tooltip,
    IconButton,
  } from '@mui/material'
import { Language } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const LandingPage = () => {
  const { t } = useTranslation();
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);

  const handleOpenLanguageMenu = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleCloseLanguageMenu = () => {
    setAnchorElLanguage(null);
  }

  const onSelectLanguageDropdown = (e) => {
    i18n.changeLanguage(e);
    setAnchorElLanguage(null);
  };

return (
    <>
        <Box sx={{ 
                mr: "1%",
                mt: "1%",
                float: "right"
            }}>
            <Tooltip title={t('open_language_picker_tooltip')}>
            <IconButton onClick={handleOpenLanguageMenu} sx={{ p: 0 }}>
                <Language alt="Language Icon" sx={{ color: 'white' }}/>
            </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElLanguage}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElLanguage)}    
                onClose={handleCloseLanguageMenu}
            >
            <MenuItem onClick={() => onSelectLanguageDropdown("en")}>English</MenuItem>
            <MenuItem onClick={() => onSelectLanguageDropdown("fr")}>Français</MenuItem>
            </Menu>
        </Box>
        <Container
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white'
            }}
        >
            <Typography
                variant="h3"
                sx={{
                    color: 'white',
                    mb: "3%"
                }}
            >
                {t('something_going_to_happen')}
            </Typography>
            <Typography
                sx={{
                    color: 'white',
                    mb: "3%"
                }}
                variant="h5"
            >
                {t('landing_page_text')}
            </Typography>
            <Link sx={{
                    color: 'white',
                    mb: "1%",
                    textDecoration: "none"
                }}
                href="https://discord.gg/aMCeZnMCPC">
                Discord
            </Link>
            <Link sx={{
                    color: 'white',
                    mb: "1%",
                    textDecoration: "none"
                }}
                href="https://twitter.com/CTP_Officiel/status/1571520451402252290">
                Twitter
            </Link>
        </Container>
    </>
)
}

export default LandingPage;